import React from 'react';
import {
    HiCheckCircle,
    HiOutlineExclamation,
    HiOutlineExclamationCircle,
    HiOutlineInformationCircle,
} from 'react-icons/hi';
import {Avatar, Button, Dialog} from 'components/ui';
import PropTypes from 'prop-types';

const StatusIcon = ({status}) => {
    switch (status) {
        case 'info':
            return (
                <Avatar
                    className="bg-blue-100 text-blue-600 dark:bg-blue-500/20 dark:text-blue-100"
                    shape="circle">
                    <span className="text-2xl">
                        <HiOutlineInformationCircle />
                    </span>
                </Avatar>
            );
        case 'success':
            return (
                <Avatar
                    className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100"
                    shape="circle">
                    <span className="text-2xl">
                        <HiCheckCircle />
                    </span>
                </Avatar>
            );
        case 'warning':
            return (
                <Avatar className="bg-amber-100 text-amber-600 dark:text-amber-100" shape="circle">
                    <span className="text-2xl">
                        <HiOutlineExclamationCircle />
                    </span>
                </Avatar>
            );
        case 'danger':
            return (
                <Avatar className="bg-red-100 text-red-600 dark:text-red-100" shape="circle">
                    <span className="text-2xl">
                        <HiOutlineExclamation />
                    </span>
                </Avatar>
            );

        default:
            return null;
    }
};

const ConfirmDialog = props => {
    const {
        type,
        title,
        children,
        onCancel,
        onConfirm,
        cancelText,
        confirmText,
        confirmButtonColor,
        loading,
        ...rest
    } = props;

    const handleCancel = () => {
        onCancel?.();
    };

    const handleConfirm = () => {
        onConfirm?.();
    };

    return (
        <Dialog
            style={{
                marginTop: 250,
            }}
            contentClassName="pb-0 px-0"
            {...rest}>
            <div className="flex px-6 pb-6 pt-2">
                <div>
                    <StatusIcon status={type} />
                </div>
                <div className="ml-4 grow rtl:mr-4">
                    <h5 className="mb-2">{title}</h5>
                    {children}
                </div>
            </div>
            <div className="rounded-bl-lg rounded-br-lg bg-gray-100 px-6 py-3 text-right dark:bg-gray-700">
                <Button size="sm" className="ltr:mr-2 rtl:ml-2" onClick={handleCancel}>
                    {cancelText}
                </Button>
                <Button
                    size="sm"
                    variant="solid"
                    onClick={handleConfirm}
                    color={confirmButtonColor}
                    loading={loading}>
                    {confirmText}
                </Button>
            </div>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'danger']),
};

ConfirmDialog.defaultProps = {
    type: 'info',
    cancelText: 'Cancel',
    confirmText: 'Confirm',
};

export default ConfirmDialog;
