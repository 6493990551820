import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './lang/en.json';
import pt from './lang/pt.json';
import es from './lang/es.json';
import fr from './lang/fr.json';
import de from './lang/de.json';

import appConfig from 'configs/app.config';

const resources = {
    en: {
        translation: en,
    },
    pt: {
        translation: pt,
    },
    es: {
        translation: es,
    },
    fr: {
        translation: fr,
    },
    de: {
        translation: de,
    },
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
});

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    pt: () => import('dayjs/locale/pt-br'),
    es: () => import('dayjs/locale/es'),
    fr: () => import('dayjs/locale/fr'),
    de: () => import('dayjs/locale/de'),
};

export default i18n;
