const appConfig = {
    apiPrefix: process.env.REACT_APP_BASE_URL,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'pt',
    enableMock: false,
};

export default appConfig;
