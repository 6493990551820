import {useDispatch, useSelector} from 'react-redux';
import {initialState, setUser} from 'store/auth/userSlice';
import {apiSignIn} from 'services/AuthService';
import {onSignInSuccess, onSignOutSuccess, setToken, setDbName} from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import {REDIRECT_URL_KEY} from 'constants/app.constant';
import {useNavigate} from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const query = useQuery();

    const {signedIn} = useSelector(state => state.auth.session);

    const signIn = async values => {
        try {
            const resp = await apiSignIn(values);
            if (resp.data) {
                dispatch(onSignInSuccess());
                dispatch(setToken(resp.data.access_token));
                dispatch(setDbName(resp.data.dbName));

                // localStorage.setItem('dbName', resp.data.dbName);

                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            },
                        ),
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        handleSignOut();
    };

    return {
        authenticated: signedIn,
        signIn,
        signOut,
    };
}

export default useAuth;
